import React from 'react';
import PropTypes from 'prop-types';

/**
 * Legend
 * @description Displays population based on colors on map
 */

export default function Legend ({ dataType }) {
  if (dataType === 'global') {
    return (
      <section className="legend-container">
        <section className="legend-header">
          <b>Population Count</b>
          <p className="hide-mobile">(estimate)</p>
        </section>
        <section className="global-key-values">
          <div className="key-value-container">
            <div className="square square1"></div>
            <p className="square-value">1 - 5</p>
          </div>
          <div className="key-value-container">
            <div className="square square2"></div>
            <p className="square-value">6 - 25</p>
          </div>
          <div className="key-value-container">
            <div className="square square3"></div>
            <p className="square-value">26 - 50</p>
          </div>
          <div className="key-value-container">
            <div className="square square4"></div>
            <p className="square-value">51 - 100</p>
          </div>
          <div className="key-value-container">
            <div className="square square5"></div>
            <p className="square-value">101 - 500</p>
          </div>
          <div className="key-value-container">
            <div className="square square6"></div>
            <p className="square-value">501 - 2500</p>
          </div>
          <div className="key-value-container">
            <div className="square square7"></div>
            <p className="square-value">2501 - 5000</p>
          </div>
          <div className="key-value-container">
            <div className="square square8"></div>
            <p className="square-value">5001 - 185000</p>
          </div>
        </section>
      </section>
    );
  } else if (dataType === 'hd' || dataType === 'us') {
    return (
      <section className="legend-container">
        <section className="legend-header">
          <b>Population Count</b>
          <p>(estimate)</p>
        </section>
        <section className="hd-us-key-values">
          <div className="key-value-container">
            <div className="square square1"></div>
            <p className="square-value">1 - 2</p>
          </div>
          <div className="key-value-container">
            <div className="square square2"></div>
            <p className="square-value">3 - 10</p>
          </div>
          <div className="key-value-container">
            <div className="square square3"></div>
            <p className="square-value">11 - 25</p>
          </div>
          <div className="key-value-container">
            <div className="square square4"></div>
            <p className="square-value">26 - 50</p>
          </div>
          <div className="key-value-container">
            <div className="square square5"></div>
            <p className="square-value">51 - 100</p>
          </div>
          <div className="key-value-container">
            <div className="square square6"></div>
            <p className="square-value">101 - 250</p>
          </div>
          <div className="key-value-container">
            <div className="square square7"></div>
            <p className="square-value">251 - 30000</p>
          </div>
        </section>
      </section>
    );
  } else return null;
}

Legend.propTypes = {
  dataType: PropTypes.string
};
