import globalMap from '../assets/images/Global_Thumbnail.png';
import usMap from '../assets/images/USA_Thumbnail.png';
import hdMap from '../assets/images/HD_Thumbnail.png';
import { bboxToLatLngBounds } from '../lib/map';
import productData from 'data/products.json';
import hdTilesData from 'data/hdTiles.json';

export const baseUrl = `https://${process.env.CNAME}/tiles`;

// Endpoint URL's for the different tiles and their corresponding bounds
export const usTiles = [
  {
    name: 'conus',
    url: `${baseUrl}/usa/{year}/conus/{time}/{z}/{x}/{y}.png`,
    bounds: [-125.0, 24.250000000000004, -66.75000023300001, 49.499999899]
  },
  {
    name: 'ak-east',
    url: `${baseUrl}/usa/{year}/ak-east/{time}/{z}/{x}/{y}.png`,
    bounds: [171.75, 48.5, 179.9999999967, 55.999999997]
  },
  {
    name: 'ak',
    url: `${baseUrl}/usa/{year}/ak/{time}/{z}/{x}/{y}.png`,
    bounds: [-179.25, 51.0, -129.90000001974, 71.4999999918]
  },
  {
    name: 'as',
    url: `${baseUrl}/usa/{year}/as/{time}/{z}/{x}/{y}.png`,
    bounds: [-171.15, -14.62499999854, -168.10000000122, -10.975]
  },
  {
    name: 'gu',
    url: `${baseUrl}/usa/{year}/gu/{time}/{z}/{x}/{y}.png`,
    bounds: [144.55, 13.175000000219999, 145.02499999981, 13.725]
  },
  {
    name: 'hi',
    url: `${baseUrl}/usa/{year}/hi/{time}/{z}/{x}/{y}.png`,
    bounds: [-178.45, 18.85000000387, -154.75000000948, 28.525]
  },
  {
    name: 'mp',
    url: `${baseUrl}/usa/{year}/mp/{time}/{z}/{x}/{y}.png`,
    bounds: [144.8, 14.02500000264, 146.17499999945002, 20.625]
  },
  {
    name: 'pr',
    url: `${baseUrl}/usa/{year}/pr/{time}/{z}/{x}/{y}.png`,
    bounds: [-68.0, 17.8250000003, -65.15000000114, 18.575000000000003]
  },
  {
    name: 'vi',
    url: `${baseUrl}/usa/{year}/vi/{time}/{z}/{x}/{y}.png`,
    bounds: [-65.175, 17.60000000035, -64.50000000026999, 18.475]
  }
];

// The HD tiles are defined in a JSON file so that they can be updated programatically
export const hdTiles = hdTilesData.map(x => ({
  ...x,
  url: x.url.replace('{{baseUrl}}', baseUrl)
}));

// Leaflet / Fogg Lens settings for LandScan Global Tile Layer
export const globalBounds = bboxToLatLngBounds([
  -180.0,
  -90.0,
  179.99999999999858,
  89.99999999999929
]);

export const globalTileLayer = {
  name: 'landscan_global',
  format: 'png',
  attribution: '&copy; LandScan Global',
  projections: 'epsg3857',
  maxZoom: 18,
  maxNativeZoom: 8,
  minZoom: 0,
  tms: true,
  tileSize: 256,
  tileEndpoint: `${baseUrl}/global/2021/{z}/{x}/{y}.png`,
  bounds: globalBounds,
  transparency: 'true',
  noWrap: false,
  opacity: 0.5
};

// Leaflet / Fogg Lens settings for LandScan HD Tile Layer
export const hdTileLayerConfig = {
  name: 'landscan_hd',
  format: 'png',
  attribution: '&copy; LandScan HD',
  projections: 'epsg3857',
  maxZoom: 18,
  maxNativeZoom: 11,
  minZoom: 0,
  tms: true,
  tileSize: 256,
  noWrap: false,
  transparency: 'true',
  opacity: 0.5
};

// Leaflet / Fogg Lens settings for LandScan US Tile Layer
export const usTileLayerConfig = {
  name: 'landscan_us',
  format: 'png',
  attribution: '&copy; LandScan USA',
  projections: 'epsg3857',
  maxNativeZoom: 11,
  maxZoom: 18,
  minZoom: 0,
  tms: true,
  year: 2020,
  time: 'day',
  tileSize: 256,
  transparency: 'true',
  noWrap: false,
  opacity: 0.5
};

export const usBounds = bboxToLatLngBounds([
  -125.0,
  24.250000000000004,
  -66.75000023300001,
  49.499999899
])
  ? bboxToLatLngBounds([
      -125.0,
      24.250000000000004,
      -66.75000023300001,
      49.499999899
    ]).pad(0.5)
  : null;

export const STREET_VIEW_VALUE = 'open_street_map';
export const SATELLITE_VIEW_VALUE = 'satellite';

export const osmTileLayer = {
  name: STREET_VIEW_VALUE,
  format: 'png',
  attribution: '&copy; OpenStreetMap contributors',
  projections: 'epsg3857',
  maxZoom: 18,
  minZoom: 0,
  tileSize: 256,
  tileEndpoint: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
};

export const satelliteTileLayer = {
  name: SATELLITE_VIEW_VALUE,
  format: 'png',
  projections: 'epsg3857',
  maxZoom: 18,
  attribution: `Map data ©${new Date().getFullYear()} Google, Imagery NASA, TerraMetrics`,
  minZoom: 0,
  tileSize: 256,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  tileEndpoint: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
};
// Leaflet / Fogg Lens settings for the Basemap + default Overlay Layers
export const mapServices = [
  osmTileLayer,
  satelliteTileLayer,
  globalTileLayer,
  hdTileLayerConfig,
  usTileLayerConfig
];

// Default Map Layers & Overlay Layers
export const mapLayers = {
  base: [
    {
      name: 'Street Maps',
      serviceName: STREET_VIEW_VALUE,
      type: 'service'
    },
    {
      name: 'Satellite',
      serviceName: SATELLITE_VIEW_VALUE,
      type: 'service'
    }
  ],
  overlay: [
    {
      name: productData.LANDSCAN_GLOBAL_LABEL,
      serviceName: 'landscan_global',
      type: 'service',
      defaultIsActive: true
    },
    {
      name: productData.LANDSCAN_HD_LABEL,
      serviceName: 'landscan_hd',
      type: 'service'
    },
    {
      name: productData.LANDSCAN_USA_LABEL,
      serviceName: 'landscan_us',
      type: 'service'
    }
  ]
};

// Different options for MiniMap Toggle
export const miniMapOptions = [
  {
    id: 'global',
    name: productData.LANDSCAN_GLOBAL_LABEL,
    img: globalMap
  },
  {
    id: 'us',
    name: productData.LANDSCAN_USA_LABEL,
    img: usMap
  },
  {
    id: 'hd',
    name: productData.LANDSCAN_HD_LABEL,
    img: hdMap
  }
];
