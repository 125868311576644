import PromiseCancelable from 'p-cancelable';
import { geocode } from 'esri-leaflet-geocoder';
import { latLngBounds, tileLayer } from 'leaflet';
import { isDomAvailable } from 'lib/util';
import {
  usTiles,
  STREET_VIEW_VALUE,
  SATELLITE_VIEW_VALUE,
  baseUrl
} from 'data/map';

/**
 * geocodePlacename
 * @description Cancellable promise that performs a geocode request on the given placename
 */

function geocodePlacename (placename) {
  return new PromiseCancelable((resolve, reject, onCancel) => {
    if (!geocode) {
      reject('geocodePlacename Error: Geocode not available.');
    }
    const request = geocode()
      .text(placename)
      .run((error, body, response) => {
        if (error) reject(error);
        resolve(response);
      }, this);

    onCancel(() => {
      request.abort();
    });
  });
}

// Customize the results returned in Autocomplete
export async function resolveGeocodeSearch (query) {
  const isInvalidQuery =
    typeof query === 'undefined' ||
    (typeof query === 'string' && query.length === 0);

  if (isInvalidQuery) {
    return [];
  }

  if (typeof query !== 'string') {
    throw new Error(`Invalid query type ${query}`);
  }

  const response = await geocodePlacename(query);
  const { candidates = [] } = response;
  return candidates.map(mapGeocodeCandidates);
}

// Format results for autocomplete
function mapGeocodeCandidates ({ address, location } = {}) {
  return {
    label: address,
    sublabel: `Location: ${location.x.toFixed(3)}, ${location.y.toFixed(3)}`,
    value: location
  };
}

export function bboxToLatLngBounds (bbox) {
  if (!isDomAvailable()) return;
  const [xmin, ymin, xmax, ymax] = bbox;
  const northEastCorner = [ymax, xmax];
  const southWestCorner = [ymin, xmin];
  return latLngBounds([southWestCorner, northEastCorner]);
}

// Takes in one of the tiles/bounds arrays from data/map.js and
// the corresponding layer config file and generates Leaflet tile Layers
export function tileLayersFromEndpoint (tileSet = [], config) {
  if (!tileSet.length) return;
  return tileSet.map(({ url, name, bounds = [], version = 'v1' }) => {
    const tileConfig = { ...config, name: name };
    if (!tileConfig.version) tileConfig.version = version;
    if (bounds.length) tileConfig.bounds = bboxToLatLngBounds(bounds);
    return tileLayer(url, tileConfig);
  });
}

// Pass in mapRef and the name of a layer to remove from map
export const removeLayerByName = (name, mapRef) => {
  if (!mapRef.current) return;
  mapRef.current.eachLayer(layer => {
    if (layer.options.name === name) {
      layer.remove();
    }
  });
};

/* Function to remove all Layers of a given type, usually so that we can replace */
export const removeLayers = (layerType = 'overlay', mapRef) => {
  if (!mapRef.current) return;

  // Remove all overlay layers EXCEPT base layers
  if (layerType === 'overlay') {
    mapRef.current.eachLayer(layer => {
      if (
        layer.options.name !== STREET_VIEW_VALUE &&
        layer.options.name !== SATELLITE_VIEW_VALUE
      ) {
        layer.remove();
      }
    });
  } else if (layerType === 'base') {
    // Only remove the 2 base layers if we specify that
    mapRef.current.eachLayer(layer => {
      if (
        layer.options.name === STREET_VIEW_VALUE ||
        layer.options.name === SATELLITE_VIEW_VALUE
      ) {
        layer.remove();
      }
    });
  }
};

export const returnUSTileUrl = ({
  time = 'day',
  year = 2020,
  location = 'conus'
}) => {
  return `${baseUrl}/usa/${year}/${location}/${time}/{z}/{x}/{y}.png`;
};

export const returnUSTiles = (time = 'day', year = 2020) => {
  const tileList = usTiles.map(({ bounds, name }) => {
    return {
      bounds: bounds,
      url: returnUSTileUrl({ time, year, location: name }),
      time: time,
      year: year
    };
  });
  return tileList;
};
