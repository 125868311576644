import { useEffect, useState } from 'react';

/**
 * useMiniMapToggle
 * @description Toggles minimap to display all minimaps and an active minimap
 * {boolean} initialToggle
 * {string} initialDataType
 */

export default function useMiniMapToggle (
  initialToggle = false,
  initialDataType = 'global'
) {
  const [toggle, setToggle] = useState(initialToggle);
  const [dataType, setDataType] = useState(initialDataType);

  useEffect(() => {
    // We had difficulty getting the map event handling required to track the
    // area of shapes being drawn on the map to correctly read the data type
    // from the useState variable returned from this hook. To work around this
    // we also save the value to a global variable.
    window.landscan = window.landscan || {};
    window.landscan.dataType = dataType;
  });

  /**
   * toggleMiniMaps
   * @description Toggles opening and closing of mini-maps
   */

  function toggleMiniMaps () {
    setToggle(!toggle);
  }

  /**
   * toggleDataType
   * @description Toggles opening and closing of mini-maps
   * {string} data
   */

  function toggleDataType (data) {
    setDataType(data);
    setToggle(false);
  }

  return {
    dataType,
    toggle,
    toggleDataType,
    toggleMiniMaps
  };
}
