import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Helmet } from 'react-helmet';
import isNil from 'lodash/isNil';
import deepCopy from 'lodash/cloneDeep';

import {
  tileLayer,
  popup,
  Polygon,
  latLngBounds,
  circle,
  GeometryUtil
} from 'leaflet';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import { Lens } from 'fogg/ui';
import calculateArea from '@turf/area';
import {
  resolveGeocodeSearch,
  tileLayersFromEndpoint,
  removeLayers,
  removeLayerByName,
  returnUSTiles
} from 'lib/map';
import {
  mapServices,
  mapLayers,
  globalBounds,
  osmTileLayer,
  satelliteTileLayer,
  miniMapOptions,
  globalTileLayer,
  usTileLayerConfig,
  hdTileLayerConfig,
  usTiles,
  hdTiles,
  baseUrl,
  STREET_VIEW_VALUE,
  SATELLITE_VIEW_VALUE
} from 'data/map';
import productData from 'data/products.json';
import {
  multiVersionCountries,
  newestHDVersions
} from 'components/VersionPicker';

import { isDomAvailable } from 'lib/util';
import useMiniMapToggle from '../hooks/useMiniMapToggle';

import MiniMap from 'components/MiniMap';
import Layout from 'components/Layout';
import Legend from 'components/Legend';

const hdCountryOptions = productData.hdCountryOptions;

// Look up 'longname' given an HD country's display name
export function hdLongname (displayname) {
  const country = hdCountryOptions.find(f => f.label === displayname);
  const longname = country.longname;
  return longname;
}

const mvcLongNames = multiVersionCountries.map(mvc => hdLongname(mvc));

const ALEXANDRIA = {
  lat: 38.8048,
  lng: -77.0469
};

// These max areas based on the point at which the stats Lambda with 1024MB of
// allocated memory began failing. If the memory allocation for the Lambda is
// changed these max areas should also be changed. See
// https://code.ornl.gov/landscan/data_explorer/ornl-backend/-/issues/59
const MAX_STATS_AREAS = Object.freeze({
  global: 18000000 * 1000 * 1000,
  us: 190000 * 1000 * 1000,
  hd: 190000 * 1000 * 1000
});

const makeAlertContent = (text, subtext) => (
  <>
    <div style={{ fontWeight: 'bold' }}>{text}</div>
    <div>{subtext}</div>
  </>
);

const AREA_TOO_LARGE_TEXT = 'Area is too large';
const AREA_TOO_LARGE_SUBTEXT = 'Select a smaller area';
const AREA_TOO_LARGE_MESSAGE = `${AREA_TOO_LARGE_TEXT}. ${AREA_TOO_LARGE_SUBTEXT}.`;
const AREA_TOO_LARGE_COMPONENT = makeAlertContent(
  AREA_TOO_LARGE_TEXT,
  AREA_TOO_LARGE_SUBTEXT
);

const IndexPage = () => {
  const mapRef = useRef(null);
  const globalLayer = useRef(globalTileLayer);
  const hdLayer = useRef(hdTiles);
  const usLayer = useRef(usTiles);
  const streetBaseLayer = useRef(osmTileLayer);
  const satelliteBaseLayer = useRef(satelliteTileLayer);
  const [timeOfDay, setTimeOfDay] = useState('day');
  const popupRef = useRef(null);
  const circleRef = useRef(null);
  const [globalYear, setGlobalYear] = useState(productData.globalActiveYear);
  const [usaYear, setUsaYear] = useState(productData.usaActiveYear);
  const [currentHDVersions, setCurrentHDVersions] = useState(newestHDVersions);
  const [populationCount, setPopulationCount] = useState(0);
  const [populationDensity, setPopulationDensity] = useState(0);
  const [latLng, setLatLng] = useState(null);
  const [loadingResults, setLoadingResults] = useState(false);
  const [currentMap, setCurrentMap] = useState(STREET_VIEW_VALUE);
  const [isPoint, setIsPoint] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(null);
  const alert = useAlert();

  // By default, show global data on map
  if (isDomAvailable()) {
    globalLayer.current = tileLayer(
      `${baseUrl}/global/${globalYear}/{z}/{x}/{y}.png`,
      globalTileLayer
    );
    streetBaseLayer.current = tileLayer(
      osmTileLayer.tileEndpoint,
      osmTileLayer
    );
    satelliteBaseLayer.current = tileLayer(
      satelliteTileLayer.tileEndpoint,
      satelliteTileLayer
    );
  }

  // Hook for keeping track of the MiniMap selection
  const {
    toggleDataType,
    toggleMiniMaps,
    toggle = false,
    dataType = 'global'
  } = useMiniMapToggle(false, 'global');

  const getMaxStatsArea = () => {
    // We had difficulty getting the map event handling required to track the
    // area of shapes being drawn on the map to correctly read the data type
    // from the value returned from the useMiniMapToggle hook. To work around
    // this we use a global variable value set by the hook code.
    if (!window.landscan.dataType) {
      console.error('Expected window.landscan.dataType to be set');
      return;
    }
    return MAX_STATS_AREAS[window.landscan.dataType];
  };

  // Watch for MiniMap toggles, change current layer based on that
  useEffect(() => {
    if (!mapRef?.current || !isDomAvailable) {
      return;
    }

    removeLayers('overlay', mapRef);
    if (dataType === 'global') {
      globalLayer.current = tileLayer(
        `${baseUrl}/global/${globalYear}/{z}/{x}/{y}.png`,
        globalTileLayer
      );

      globalLayer.current.addTo(mapRef.current);
    } else if (dataType === 'us') {
      usLayer.current = tileLayersFromEndpoint(
        returnUSTiles(timeOfDay, usaYear),
        usTileLayerConfig
      );

      usLayer.current.map(layer => layer.addTo(mapRef.current));
    } else if (dataType === 'hd') {
      // Filter out and replace any HD Tiles that need a specific version
      const tiles = hdTiles.filter(({ name }) => !mvcLongNames.includes(name));
      const versionedTiles = hdTiles.filter(({ name }) =>
        mvcLongNames.includes(name)
      );
      for (const vtile of versionedTiles) {
        vtile.version = currentHDVersions.filter(
          hd => hd.country === vtile.name
        )[0].version;
        tiles.push(vtile);
      }

      hdLayer.current = tileLayersFromEndpoint(tiles, hdTileLayerConfig);
      hdLayer.current.map(layer => layer.addTo(mapRef.current));
    }
  }, [
    currentHDVersions,
    dataType,
    globalLayer,
    globalYear,
    usaYear,
    hdLayer,
    usLayer,
    timeOfDay,
    zoomLevel
  ]);

  // Request population details for a given geometry/location on map
  function postStatsAPI (dataset = 'global', year = 2020, geometry = {}) {
    setLoadingResults(true);
    const requestBody = {
      dataset: dataset,
      geometry: geometry,
      year: year
    };
    if (dataset === 'us') {
      requestBody.day_night = timeOfDay;
    } else if (dataset === 'hd') {
      requestBody.versions = currentHDVersions;
    }

    const reportFailure = (
      text = 'There was a problem getting population statistics',
      subtext = 'Please try again with a smaller area'
    ) => {
      setLoadingResults(false);
      setPopulationCount(null);
      setPopulationDensity(null);
      alert.show(makeAlertContent(text, subtext));
    };

    axios
      .post(process.env.API_ENDPOINT + '/stats', requestBody)
      .then(response => {
        setLoadingResults(false);
        if (response.status !== 200) {
          reportFailure();
          return;
        }
        if (
          isNil(response.data?.properties?.sum) ||
          isNil(response.data?.properties?.density)
        ) {
          reportFailure(
            'There was a problem getting population statistics',
            'The sum or density was not available'
          );
          return;
        }
        setPopulationCount(response.data.properties.sum);
        setPopulationDensity(response.data.properties.density);
      })
      .catch(error => {
        reportFailure();
        console.error('ERROR getting stats', error);
      });
  }

  // Handler for any search by search bar OR by drawing on map
  async function handleResolveOnEarthSearch (args) {
    const { geoJson = {}, textInput } = args;
    const { features = [] } = geoJson;
    const { geometry = {} } = features[0] || {};
    // Reset results and coordinates on new search
    setLatLng(null);
    setPopulationCount(null);
    setPopulationDensity(null);

    const { type, coordinates = [] } = geometry;
    // Only search if there is NO text input string to avoid confusing results
    if (mapRef.current && !textInput) {
      // Remove existing popup if any
      mapRef.current.closePopup();
      if (mapRef.current.hasLayer(circleRef.current)) {
        mapRef.current.removeLayer(circleRef.current);
      }
      let area;
      if (type === 'Point') {
        setLatLng([coordinates[1], coordinates[0]]);
        setIsPoint(true);
        // area Estimate rounded up to 3.23km, then converted to radius
        area = 3.23 * 1000 * 100;
        const radius = Math.sqrt(area / Math.PI);
        circleRef.current = circle(
          [coordinates[1], coordinates[0]],
          radius
        ).addTo(mapRef.current);
        // mapRef.current.fitBounds(circleRef.current.getBounds().pad(1));
      } else if (type === 'Polygon') {
        area = calculateArea(geoJson);
        const polygon = new Polygon(coordinates);
        const coords = polygon.getBounds().getCenter();

        setLatLng([coords.lng, coords.lat]);
        setIsPoint(false);
      }
      if (area > getMaxStatsArea()) {
        alert.show(AREA_TOO_LARGE_COMPONENT);
        return;
      }
      const year = dataType === 'us' ? usaYear : globalYear;
      await postStatsAPI(dataType, year, geoJson);
    }
  }

  // Watch for search results from Stats API and display in Popup
  useEffect(() => {
    if (populationDensity != null && latLng) {
      popupRef.current = popup({ offset: [0, -30] })
        .setLatLng(latLng)
        .setContent(
          '<div class="popup-header">Population</div>' +
            `<div class="popup-body">
          <p>Density<br/><strong>${Number(
            populationDensity
          ).toLocaleString()}</strong><br/><small>people / km²</small></p>
          ${
            !isPoint
              ? `<p>Count<br/><strong>${Number(
                  populationCount
                ).toLocaleString()}</strong><br/><small>people</small>
          </p>`
              : ''
          }</div>`
        )
        .openOn(mapRef.current);
    }
  }, [populationCount, populationDensity, latLng, setLatLng, isPoint]);

  function calculateLayerArea (layer) {
    if (!layer || !layer._latlngs) {
      return 0;
    }
    return layer._latlngs.reduce(
      (acc, latlngs) => acc + GeometryUtil.geodesicArea(latlngs),
      0
    );
  }

  // This keeps mapRef up-to-date with the Lens leaflet map
  function mapEffect ({ leafletElement: map } = {}) {
    mapRef.current = map;
    if (isDomAvailable()) {
      // Restrict map bounds to prevent duplicates/coordinate issues
      map.options.maxBounds = latLngBounds([-80, -200], [90, 200]);
      // From the Leaflet docs:
      //     If maxBounds is set, this option will control how solid the bounds
      //     are when dragging the map around [...] 1.0 makes the bounds fully
      //     solid, preventing the user from dragging outside the bounds.
      // Because we set the bounds to the entire world, we do not want to allow
      // dragging "outside" the bounds beacuase that causes the map to wrap
      // around
      map.options.maxBoundsViscosity = 1.0;

      // Keep track of zoom level
      map.on('zoomend', () => {
        setZoomLevel(map.getZoom());
      });

      let isDrawing = false;
      let inProgressLayerType = null;
      let rectangleLayerInProgress = null;
      let rectangleLayerInProgressOriginalOptions = null;

      map.on('draw:drawstart', e => {
        isDrawing = true;
        inProgressLayerType = e.layerType;
        pollDrawLayerArea();
      });

      map.on('draw:drawstop', e => {
        isDrawing = false;
        inProgressLayerType = null;
        rectangleLayerInProgress = null;
      });

      const pollDrawLayerArea = () => {
        if (isDrawing) {
          if (inProgressLayerType === 'rectangle' && rectangleLayerInProgress) {
            const area = calculateLayerArea(rectangleLayerInProgress);
            if (area > getMaxStatsArea()) {
              rectangleLayerInProgress.setStyle(
                Object.assign({}, rectangleLayerInProgress.options, {
                  color: '#ff0000'
                })
              );
              const ha = parseFloat(area * 0.0001).toFixed(2);
              const tt = document.getElementsByClassName(
                'leaflet-draw-tooltip'
              )[0];
              tt.innerHTML = `<span class="leaflet-draw-tooltip-subtext">${ha} ha</span><br><span>${AREA_TOO_LARGE_MESSAGE}</span>`;
            } else {
              rectangleLayerInProgress.setStyle(
                rectangleLayerInProgressOriginalOptions
              );
            }
          }
          setTimeout(pollDrawLayerArea, 200);
        }
      };

      map.on('layeradd', e => {
        if (isDrawing && inProgressLayerType === 'rectangle') {
          rectangleLayerInProgress = e.layer;
          rectangleLayerInProgressOriginalOptions = Object.assign(
            {},
            rectangleLayerInProgress.options
          );
        }
      });
    }
  }

  function handleDayNightToggle (timeOfDay) {
    setTimeOfDay(timeOfDay);
  }

  // Store selected year in state
  function handleYearToggle (year) {
    if (dataType === 'global') {
      setGlobalYear(year);
    } else if (dataType === 'us') {
      setUsaYear(year);
    }
  }

  // Store selected version in state & replace tile layer with selected version
  function handleVersionToggle (country, version) {
    setCurrentHDVersions(
      currentHDVersions.map(o =>
        o.country === country
          ? Object.assign(deepCopy(o), { version })
          : deepCopy(o)
      )
    );

    const newConfig = { ...hdTileLayerConfig, version: version };

    if (mapRef?.current && isDomAvailable) {
      removeLayerByName(country, mapRef);

      const currentHdTile = hdTiles.filter(obj => obj.name === country);
      const tile = tileLayersFromEndpoint(currentHdTile, newConfig);
      if (tile) tile.map(layer => layer.addTo(mapRef.current));
    }
  }

  // Handler for LandScan product selection via MiniMap
  function handleMapToggle (map) {
    setCurrentMap(map);
    if (!mapRef?.current || !isDomAvailable) return;
    removeLayers('base', mapRef);
    if (map === SATELLITE_VIEW_VALUE) {
      satelliteBaseLayer.current.addTo(mapRef.current).bringToBack();
    } else if (map === STREET_VIEW_VALUE) {
      streetBaseLayer.current.addTo(mapRef.current).bringToBack();
    }
  }

  const activeMiniMap =
    miniMapOptions.find(option => option.id === dataType) || {};
  activeMiniMap.dayOrNight = 'day';

  const handleHDCountryClick = bounds => {
    mapRef.current.fitBounds(bounds);
  };

  return (
    <Layout className="fullscreen" showFooter={false}>
      <Helmet bodyAttributes={{ class: 'page-home' }}>
        <title>ORNL LandScan Viewer - Oak Ridge National Laboratory</title>
      </Helmet>
      <Lens
        defaultCenter={ALEXANDRIA}
        minZoom={3}
        maxZoom={18}
        maxNativeZoom={8}
        maxBounds={globalBounds}
        defaultZoom={3}
        projection="epsg3857"
        placeholder="Search location or coordinates"
        resolveOnSearch={handleResolveOnEarthSearch}
        map={STREET_VIEW_VALUE}
        resolveOnAutocomplete={resolveGeocodeSearch}
        availableServices={mapServices}
        availableLayers={mapLayers}
        useMapEffect={mapEffect}
        hideNativeLayers={true}
        hideDatetime={true}
      />
      <MiniMap
        options={miniMapOptions}
        activeMiniMap={activeMiniMap}
        toggle={toggle}
        toggleMiniMaps={toggleMiniMaps}
        toggleDataType={toggleDataType}
        handleDayNightToggle={handleDayNightToggle}
        globalYear={globalYear}
        usaYear={usaYear}
        handleHDCountryClick={handleHDCountryClick}
        handleYearToggle={handleYearToggle}
        handleVersionToggle={handleVersionToggle}
        currentHDVersions={currentHDVersions}
        handleMapToggle={handleMapToggle}
        currentMap={currentMap}
      />
      <Legend dataType={dataType} />
      {loadingResults && (
        <div className="loading-spinner">
          <FaSpinner />
        </div>
      )}
    </Layout>
  );
};

export default IndexPage;
