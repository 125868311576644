import React from 'react';
import PropTypes from 'prop-types';

import { mapLayers } from 'data/map';

/**
 * BaseMapSelector
 * @description Displays a two buttons to toggle between map layers
 */

export default function BaseMapSelector ({
  options = mapLayers.base,
  currentMap,
  handleMapSelection
}) {
  return (
    <section className="map-selector-container">
      {/* when button  */}
      {options.map(({ name, serviceName }, key) => (
        <button
          className={`button button-primary basemap-selector-button 
            ${currentMap === serviceName ? 'active' : ''}`}
          key={key}
          onClick={() => handleMapSelection(serviceName)}
        >
          {name}
        </button>
      ))}
    </section>
  );
}

BaseMapSelector.propTypes = {
  options: PropTypes.array,
  currentMap: PropTypes.string,
  handleMapSelection: PropTypes.func
};
