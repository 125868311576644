import React from 'react';
import PropTypes from 'prop-types';
import { FaCalendarAlt } from 'react-icons/fa';
import productData from 'data/products.json';

/**
 * YearPicker
 * @description Displays a popup with years to select different global datasets
 */

function YearPicker ({
  product = 'global',
  activeYear = productData.globalActiveYear,
  showYearPicker = false,
  handleYearToggle,
  handleShowYearPicker
}) {
  function handleYearSelection (date) {
    handleYearToggle(date);
    handleShowYearPicker(false);
  }
  let years = productData.yearOptions; // Default to global year list

  // If LandScan US is selected, filter available years
  if (product === 'us') {
    years = productData.yearOptions.filter(({ value }) =>
      productData.usYearOptions.includes(value)
    );
  }

  return (
    <div className="yearpicker-container">
      <div className={`yearpicker ${showYearPicker ? 'show' : 'hide'}`}>
        {years.map(({ value: year }, id) => (
          <button
            className={`button ${year === activeYear ? 'active' : ''}`}
            key={id}
            onClick={() => handleYearSelection(year)}
          >
            {year}
          </button>
        ))}
      </div>
      <button
        className="button button-primary toggle-years"
        onClick={() => handleShowYearPicker(!showYearPicker)}
      >
        <span className="calendar-icon">
          <FaCalendarAlt />
        </span>
        <span className="active-year">{activeYear}</span>
      </button>
    </div>
  );
}

YearPicker.propTypes = {
  product: PropTypes.string,
  activeYear: PropTypes.number,
  showYearPicker: PropTypes.bool,
  handleYearToggle: PropTypes.func,
  handleShowYearPicker: PropTypes.func
};
export default YearPicker;
