import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaPlus, FaMinus } from 'react-icons/fa';
import productData from 'data/products.json';
import { hdTiles } from 'data/map';
import { bboxToLatLngBounds } from 'lib/map';

// Get a list of HD countries with multiple versions
var multiVersions = productData.hdCountryOptions.filter(
  country => country.version
);

function reverseVersionCompare (a, b) {
  if (a.version < b.version) return 1;
  if (a.version > b.version) return -1;
  return 0;
}
// newest versions first: they'll appear first in options and be default layers
multiVersions.sort(reverseVersionCompare);

export const multiVersionCountries = [
  ...new Set(
    multiVersions.map(function (v) {
      return v.label;
    })
  )
];

export var newestHDVersions = [];
for (const m of multiVersions) {
  const country = newestHDVersions.find(f => f.label === m.label);
  if (!country) {
    newestHDVersions.push({
      label: m.label,
      country: m.longname,
      version: m.version
    });
  } else if (country.version < m.version) {
    country.version = m.version;
  }
}

export const hdVersionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
});

/**
 * VersionPicker
 * @description Displays a popup with HD products that have multiple
 * versions and the ability to select them
 */

function VersionPicker ({
  activeVersion,
  showVersionPicker = false,
  handleHDCountryClick,
  handleVersionToggle,
  currentHDVersions,
  handleShowVersionPicker
}) {
  const [pickerIcon, setPickerIcon] = useState(
    <FaPlus className="version-icon" />
  );

  // Change handler for the Version dropdown
  function handleVersionChange (event, country) {
    const version = event.target.value;
    handleVersionToggle(country, version);
  }

  // Toggle open/close version picker and the +/- icon
  function triggerVersionPicker () {
    handleShowVersionPicker(!showVersionPicker);
    setPickerIcon(
      showVersionPicker ? (
        <FaPlus className="version-icon" />
      ) : (
        <FaMinus className="version-icon" />
      )
    );
  }

  const getCountryBounds = countryName => {
    const country = hdTiles.find(country => country.name === countryName);
    const bounds = country ? bboxToLatLngBounds(country.bounds) : [];
    handleHDCountryClick(bounds);
  };

  // Get array of countries sorted alphabetically in ascending order
  const sortedHDCountries = productData.hdCountryOptions.sort((a, b) => {
    return !a.version - !b.version;
  });

  // Get array of unique countries
  const uniqueHDCountries = sortedHDCountries.filter(country => {
    return country.version !== 'v1';
  });

  const getCurrentHDVersionForCountry = longname =>
    currentHDVersions.find(o => o.country === longname)?.version;

  return (
    <div className="versionpicker-container">
      <ul className={`versionpicker ${showVersionPicker ? 'show' : 'hide'}`}>
        {multiVersions?.length &&
          uniqueHDCountries.map((hdCountry, index) => {
            return (
              <li key={index}>
                <button
                  className="country-label"
                  onClick={() => getCountryBounds(hdCountry.longname)}
                >
                  {hdCountry.label}{' '}
                </button>
                {hdCountry.version ? (
                  // The disabled jsx-a11y/no-onchange rule says `onBlur` is
                  // preferred but React requires we use `onChange` when setting
                  // `value`
                  <select // eslint-disable-line jsx-a11y/no-onchange
                    value={getCurrentHDVersionForCountry(hdCountry.longname)}
                    onChange={e => handleVersionChange(e, hdCountry.longname)}
                  >
                    {multiVersions.map((country, i) => {
                      if (country.label === hdCountry.label) {
                        return (
                          <option key={i} value={country.version}>
                            {country.version}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </select>
                ) : null}
              </li>
            );
          })}
      </ul>
      <button
        className="button button-primary toggle-version"
        onClick={triggerVersionPicker}
      >
        <span className="label">Versions</span>
        {pickerIcon}
      </button>
    </div>
  );
}

VersionPicker.propTypes = {
  activeVersion: PropTypes.object,
  showVersionPicker: PropTypes.bool,
  handleHDCountryClick: PropTypes.func,
  handleVersionToggle: PropTypes.func,
  currentHDVersions: PropTypes.arrayOf(hdVersionShape).isRequired,
  handleShowVersionPicker: PropTypes.func
};
export default VersionPicker;
