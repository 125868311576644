import React from 'react';
import PropTypes from 'prop-types';

import { FaSun, FaMoon } from 'react-icons/fa';

const DayNightToggle = ({ activeToggle, handleToggleClick }) => {
  return (
    <div className={`day-night-toggle ${activeToggle}`}>
      <button
        className="day-toggle"
        onClick={() => handleToggleClick('day')}
        aria-labelledby="Day"
      >
        <FaSun />
        Day
      </button>
      <button
        className="night-toggle"
        onClick={() => handleToggleClick('night')}
        aria-labelledby="Night"
      >
        <FaMoon />
        Night
      </button>
    </div>
  );
};

DayNightToggle.propTypes = {
  activeToggle: PropTypes.string,
  handleToggleClick: PropTypes.func
};

export default DayNightToggle;
