import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayNightToggle from './DayNightToggle';
import YearPicker from './YearPicker';
import VersionPicker, { hdVersionShape } from './VersionPicker';
import BaseMapSelector from './BaseMapSelector';
import productData from 'data/products.json';

/**
 * MiniMap
 * @description Displays a mini map to select map products, layers, etc.
 */

function MiniMap ({
  options = [],
  activeMiniMap = {},
  toggle = false,
  toggleMiniMaps,
  toggleDataType,
  handleDayNightToggle,
  globalYear,
  usaYear,
  handleYearToggle,
  handleHDCountryClick,
  handleVersionToggle,
  currentHDVersions,
  handleMapToggle,
  currentMap
}) {
  const { name, img, dayOrNight = 'day' } = activeMiniMap;

  // Day/Night Toggle to show for LandScan US
  const [dayNightToggle, setDayNightToggle] = useState(dayOrNight);
  function handleToggleClick (value) {
    setDayNightToggle(value);
    handleDayNightToggle(value);
  }

  // Global Year selection picker handlers/state
  const [showYearPicker, setShowYearPicker] = useState(false);
  function handleYearSelection (date) {
    handleYearToggle(date);
    setShowYearPicker(false);
  }

  // HD Version selection picker handlers/state
  const [showVersionPicker, setShowVersionPicker] = useState(false);
  function handleVersionSelection (product, version) {
    handleVersionToggle(product, version);
    setShowYearPicker(false);
  }

  return (
    <section className="mini-map-container">
      {name === productData.LANDSCAN_USA_LABEL && (
        <>
          <YearPicker
            product="us"
            activeYear={usaYear}
            showYearPicker={showYearPicker}
            handleYearToggle={handleYearSelection}
            handleShowYearPicker={() => setShowYearPicker(!showYearPicker)}
          />
          <DayNightToggle
            activeToggle={dayNightToggle}
            handleToggleClick={handleToggleClick}
          />
        </>
      )}
      {name === productData.LANDSCAN_GLOBAL_LABEL && (
        <YearPicker
          product="global"
          activeYear={globalYear}
          showYearPicker={showYearPicker}
          handleYearToggle={handleYearSelection}
          handleShowYearPicker={() => setShowYearPicker(!showYearPicker)}
        />
      )}
      {name === productData.LANDSCAN_HD_LABEL && (
        <VersionPicker
          showVersionPicker={showVersionPicker}
          handleHDCountryClick={handleHDCountryClick}
          handleVersionToggle={handleVersionSelection}
          currentHDVersions={currentHDVersions}
          handleShowVersionPicker={() =>
            setShowVersionPicker(!showVersionPicker)
          }
        />
      )}
      <button
        className="mini-map"
        onClick={toggleMiniMaps}
        style={{ backgroundImage: `url(${img})` }}
        aria-label={`${name} map`}
      >
        <label className="mini-map-title">{name}</label>
      </button>
      {toggle && (
        <section className="map-selection">
          <div className="mini-maps-group">
            <BaseMapSelector
              handleMapSelection={handleMapToggle}
              currentMap={currentMap}
            />
            {options.map(({ id, name, img }, key) => (
              <button
                className={`mini-map mini-maps-group-member ${
                  activeMiniMap && activeMiniMap.name === name ? 'active' : ''
                }`}
                key={key}
                onClick={() => toggleDataType(id)}
                style={{ backgroundImage: `url(${img})` }}
                aria-label={`${name} map`}
              >
                <label className="mini-maps-group-member-title member-global">
                  {name}
                </label>
              </button>
            ))}
          </div>
        </section>
      )}
    </section>
  );
}

MiniMap.propTypes = {
  options: PropTypes.array,
  activeMiniMap: PropTypes.object,
  toggle: PropTypes.bool,
  toggleMiniMaps: PropTypes.func,
  toggleDataType: PropTypes.func,
  handleDayNightToggle: PropTypes.func,
  globalYear: PropTypes.number,
  usaYear: PropTypes.number,
  handleYearToggle: PropTypes.func,
  handleMapToggle: PropTypes.func,
  currentMap: PropTypes.string,
  handleHDCountryClick: PropTypes.func,
  handleVersionToggle: PropTypes.func,
  currentHDVersions: PropTypes.arrayOf(hdVersionShape).isRequired
};

export default MiniMap;
